<template>
  <div>
    <a-card title="应收欠款">
      <a-row gutter="16">
        <a-col
          :span="24"
          :md="8"
          :xl="6"
          style="max-width: 256px; margin-bottom: 12px"
        >
          <a-input-search
            v-model="searchForm.search"
            placeholder="编号"
            allowClear
            @search="search"
          />
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button @click="viewPaymentOrders(item)">查看付款单</a-button>
              <a-button type="primary" @click="createPaymentOrder(item)"
                >创建收款单</a-button
              >
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { arrearsReceivableList } from "@/api/finance";

export default {
  name: "SaleRecord",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          fixed: "left",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "编号",
          dataIndex: "number",
          fixed: "left",
        },
        {
          title: "客户名称",
          dataIndex: "name",
        },
        {
          title: "单数",
          dataIndex: "count_orders",
        },
        {
          title: "欠款金额",
          dataIndex: "total_order_amount",
        },
        {
          title: "操作",
          dataIndex: "action",
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { page: 1, has_arrears: true, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      arrearsReceivableList(this.searchForm)
        .then((data) => {
          // this.pagination.total = data.count;
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate
        ? startDate.format("YYYY-MM-DD")
        : undefined;
      this.searchForm.end_date = endDate
        ? endDate.add(1, "days").format("YYYY-MM-DD")
        : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    viewPaymentOrders(client) {
      // 跳转到查看付款单页面，可以传递客户ID
      this.$router.push({
        path: "/finance/payment_orders_view",
        query: { clientId: client.id },
      });
    },
    createPaymentOrder(client) {
      // 跳转到创建收款单页面，可以传递客户ID
      this.$router.push({
        path: "/finance/payment_orders_create",
        query: { clientId: client.id },
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>